import { SplitCircleColours as COLOURS, e3Legend } from '@src/modules/performance/shared/constants';
import { useEffect, useState } from 'react';
import { useE3Kpi } from '@src/modules/performance/context/reports-api-hooks';
import { Text } from '@chakra-ui/react';
import Widget from './Widget';
import { SplitCircleIndicator } from '../Indicators';
import IndicatorLegend from '../IndicatorLegend';

const colourMap = {
  1: COLOURS.LIGHT_BLUE,
  2: COLOURS.BLUE,
  3: COLOURS.DARK_BLUE,
  default: 'GREY',
};

const labelMap = {
  economy: 'Value for Money',
  effectiveness: 'Effectiveness',
  efficiency: 'Efficiency',
};

export default function E3Widget() {
  const {
    data: e3KpiData, isLoading, isError,
  } = useE3Kpi();

  const title = 'Value for Money, Efficiency\n& Effectiveness';
  const description = 'Cost / Quantity / Quality\nBenchmarked';
  const [colours, setColours] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    if (e3KpiData?.data) {
      setColours(Object.values(e3KpiData.data).map((x) => colourMap[x] || colourMap.default));
      setLabels(Object.keys(e3KpiData.data || []).map((x) => labelMap[x]));
    }
  }, [e3KpiData?.data]);

  // If there is an error and no data we want to hide the widget
  if (isError && !e3KpiData?.data) return null;

  return (
    <Widget
      link={null}
      title={title}
      description={description}
      lockYear={e3KpiData?.lockYear}
      quarter={null}
      isLoading={isLoading && !e3KpiData}
      moreInfoContent={(
        <>
          <Text fontSize="sm" mb={4}>
            <Text as="span" fontWeight="bold">Value for money:</Text>
            {' '}
            how good is the network for the amount spent. (Quality / Cost)
          </Text>
          <Text fontSize="sm" mb={4}>
            <Text as="span" fontWeight="bold">Efficiency:</Text>
            {' '}
            how much is done for the amount spent (Quantity / Cost)
          </Text>
          <Text fontSize="sm" mb={4}>
            <Text as="span" fontWeight="bold">Effectiveness:</Text>
            {' '}
            how good is the network for the amount of work done. (Quality / Quantity)
          </Text>
          <IndicatorLegend items={e3Legend} />
        </>
      )}
    >
      <SplitCircleIndicator
        size={100}
        colours={colours}
        labels={labels}
        isLoading={isLoading && !e3KpiData}
      />
    </Widget>
  );
}
