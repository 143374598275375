import { Text } from '@chakra-ui/react';
import { selectedDatabaseIdState } from '@transport-insights/uikit';
import { useRecoilValue } from 'recoil';
import Widget from './Widget';
import { CircleIndicator } from '../Indicators';
import { useTemporaryTrafficManagementKpi } from '../../context/temporary-traffic-management-api-hooks';
import { roundKpiValue } from '../../shared/helpers';

export default function TemporaryTrafficManagementWidget() {
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const { data: ttmData, isLoading, isError } = useTemporaryTrafficManagementKpi();

  const title = 'Temporary Traffic\nManagement';
  const description = 'NLTP Expenditure (%)';

  // If there is an error and no data we want to hide the widget
  if (isError && (!ttmData || typeof ttmData.value !== 'number')) return null;

  return (
    <Widget
      link={null}
      title={title}
      description={description}
      lockYear={ttmData?.quadLockYear}
      quarter={ttmData?.quarter}
      isLoading={isLoading && !ttmData}
      moreInfoContent={(
          rcaId === 81 ? (
            <Text fontSize="sm">The proportion spent on Temporary Traffic Management within the 3-year NLTP spend on State highway pothole prevention, State highway operations, and State highway improvements.</Text>
          ) : (
            <Text fontSize="sm">The proportion spent on Temporary Traffic Management within the 3-year NLTP spend on Local road pothole prevention, Local road operations, and Local road improvements.</Text>
          )
      )}
    >
      <CircleIndicator
        size={100}
        colour={ttmData?.value !== undefined ? 'gray.700' : null}
        value={ttmData?.value !== undefined ? `${roundKpiValue(ttmData?.value, 5, 1)}%` : undefined}
        isLoading={isLoading || !ttmData}
      />
    </Widget>
  );
}
