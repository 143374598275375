import { TrafficLightColours as COLOURS, stateHighwaysPotholeRepairsLegend } from '@src/modules/performance/shared/constants';
import { useStateHighwaysPotholeRepairsKPI } from '@src/modules/performance/context/reports-api-hooks';
import { Text } from '@chakra-ui/react';
import Widget from './Widget';
import { CircleIndicator } from '../Indicators';
import { mapValueToColourRange, roundKpiValue } from '../../shared/helpers';
import IndicatorLegend from '../IndicatorLegend';

export default function StateHighwayPotholeRepairsWidget() {
  const { data: stateHighwaysPotholeRepairsData, isLoading, isError } = useStateHighwaysPotholeRepairsKPI();

  const title = 'State Highways\nPothole Repairs';
  const description = '% completed within 24 hours';
  const colour = mapValueToColourRange(stateHighwaysPotholeRepairsData?.percentAchieved, 'stateHighwaysPotholeRepairsKpi');

  // If there is an error and no data we want to hide the widget
  if (isError && (stateHighwaysPotholeRepairsData?.percentAchieved === undefined || !stateHighwaysPotholeRepairsData?.percentAchieved)) return null;

  return (
    <Widget
      link="/performance/reports/state-highways-pothole-repairs"
      title={title}
      description={description}
      lockYear={stateHighwaysPotholeRepairsData?.lockYear}
      quarter={stateHighwaysPotholeRepairsData?.quarter}
      isLoading={isLoading && !stateHighwaysPotholeRepairsData}
      moreInfoContent={(
        <>
          <Text fontSize="sm" mb={4}>
            The percentage of potholes on all State Highways that were repaired within 24 hours of being notified to NZTA, in the year to the reported quarter.
          </Text>
          <IndicatorLegend items={stateHighwaysPotholeRepairsLegend} />
        </>
      )}
    >
      <CircleIndicator
        size={90}
        colour={COLOURS[colour]}
        value={stateHighwaysPotholeRepairsData?.percentAchieved !== null && stateHighwaysPotholeRepairsData?.percentAchieved !== undefined ? `${roundKpiValue(stateHighwaysPotholeRepairsData?.percentAchieved, 5, 1)}%` : null}
        isLoading={isLoading}
        padding={0}
      />
    </Widget>
  );
}
